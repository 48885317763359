// USA
export const locale = {
	lang: 'en',
	data: {
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			REPORTS: 'Reports',
			DYNAMIC_REPORTS: 'Dynamic Reports',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard',
			AUTHORIZATION: 'Authorization',
			FISH_SCHOOLS: 'Fish schools',
			FISH_SCHOOLS_NEW: 'New',
			FISH_SCHOOLS_TOGGLE_STATUS: 'Toggle Status',
			VIEW_SOLD_FISH_SCHOOL: 'View Sold',
			SALE_FISH_SCHOOL: 'Sale',
			FARM: 'Farm',
			FOOD_NAMES: 'Feed Names',
			FEED_PLAN: 'Feed Plan',
			FEED_UPDATE: 'Feed Update',
			DELIVERY_NOTES: 'Delivery Notes',
			HISTORY: 'Merge History',
			TRANSFER: 'Transfer',
			MORTALITY: 'Mortality',
		},
		TOP_BAR: {
			LOGOUT: 'Logout',
			HELLO: 'Hello ',
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				ADD_CAGE_BUTTON: 'Add cage',
				ADD_CLEAR_ALL_CAGES_BUTTON: 'Clear all cages',
				ADD_CLEAR_LAST_CAGE_BUTTON: 'Clear last cage',
				NO_ACCOUNT: 'Don\'t have an account?',
				SIGNUP_BUTTON: 'Signup',
				FORGOT_BUTTON: 'Forgot Password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In',
				USERNAME: 'Username',
				PASSWORD: 'Password'
			},
			FORGOT: {
				TITLE: 'Forgotten Password?',
				DESC: 'Enter your email to reset your password',
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered. Please use your registered account to login.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',
				CONNECTION_FAILURE: 'Unable to connect to the server, please try again later',
				AUTHORIZATION: 'Authorizations',
				SCHOOL_SUMMARY: 'School summary',
				DAILY_FARM: 'Daily farm',
				UPDATE_FAILY_FARM: 'Update daily farm',
				FS_NAMES: 'Fish school names',
				FUTURE_DATE: 'Can not choose future date',
				REPORT_NAME: 'Report Name',
				REPORT_FIELD_NAME: 'Report Field Name',
				FOOD: 'Feed',
				UPDATE_FOOD: 'Update feed',
				DELETE_FOOD: 'Delete feed',
				FS: 'Fish school',
				UPDATE_FS: 'Update Fish school',
				FOOD_NAMES: 'Feed names',
				NEW_FS: 'New Fish school',
				SOLD_FS: 'Sold Fish school',
				UPDATE_SOLD_FS: 'Update sold Fish school',
				TOGGLE_STATUS_FS: 'Toggel Fish school status',
				VIEW_SOLD_FS: 'View sold Fish school',
				FOOD_SOLTS: 'Feed slots',
				DELIVERY_NOTES: 'Delivery notes',
				UPDTAE_DELIVERY_NOTES: 'Update delivery notes',
				DELETE_DELIVERY_NOTES: 'Delete delivery notes',
				NO_FISH_SOURCE: 'No Fish Source was entered',
				NO_STAGE: 'No Fish Stage was entered',
				NO_TOTAL_MOVED_FISH: 'No Total Moved Fish number was entered',
				NO_AVERAGE_WEIGHT: 'No Average Weight was entered',
				WRONG_DATE_ORDER: 'Wrong order of dates were entered',

			},
		},
		GENERAL: {
			F_C_R: 'F.C.R.',
			ACTUAL_GIVEN_FOOD: 'Total Feed',
			FOOD_NAME: 'Feed Name',
			AGE: 'Age',
			STAGE: 'Stage',
			NET: 'Net',
			LOST_TYPE: 'Lost Type',
			SORT_SIZE: 'Sort Size',
			FISH_SOURCE: 'Fish Source',
		},
		DASHBOARD: {
			FARM_SUMMARY: 'Farm summary',
			SUMMARY: 'Summary',
			AVG_WEIGHT: 'AVG',
			TOTAL_WEIGHT: 'Total BM',
			NUM_OF_FISH: 'Total Fish',
			FOOD_WEIGHT: 'Feed Weight',
			GIVEN_FOOD: 'Actual Feed',
			PLAN_FOOD: 'Plan Feed',
			QUANTITY: 'Quantity',
			PREFIX: 'Prefix',
			FEED_DATE: 'Feed Date',
			FCR: 'Fcr',
			FOOD_TYPE: 'Feed Type',
			DETAILS: {
				SUMMARY_HEADER: 'Summary Details',
				NAME: 'Name',
				FEED_DATE: 'Feed Date',
			},
			SUMMARY_DATE: 'Summary Date',
		},
		VALIDATION: {
			LOAD_FS_FAILURE: 'Unable to load Fish School records, please try again later',
			LOAD_FOOD_FAILURE: 'Unable to load Food records, please try again later',
			LOAD_FOOD_DELIVERY_NOTES_FAILURE: 'Unable to load Delivery Notes records, please try again later',
			REQUIRED_FIELD: '{{name}} is required',
			MIN_LENGTH_FIELD: '{{name}} minimum length is {{min}}',
			NO_RECORDS: 'No records to display',
			NO_CAGES_SELEDTED: 'No cage has been selected to display',
			NO_CHANGES: 'No changes detected, will not update records',
			LOAD_AUTHORIZATIONS_FAILURE: 'Unable to load authroizations records, please try again later',
			FISH_SCHOOL_MULTI_UPDATE: 'You may update only 1 day at the time',
		},
		FARM: {
			ACTUAL_GIVEN_FOOD: 'Actual Given Feed',
			MORTALITY: 'Mortality',
			SOLD: 'Sold',
			ACTIVE: 'Active',
			INACTIVE: 'INACTIVE',
			HISTORY: 'Cage History',
			CAGE: 'Cage View',
			FISH: 'Fish View',
			AGE: 'Age',
			DATE: 'Date',
			SELF: 'Self',
			ROCK: 'Rock',
			BOTH: 'Both',
			CAGES: 'Current Cage',
			STOCKING: 'Stocking',
			WHOLE: 'Whole Farm',
			STAGE: 'Stage view',
			HATCHERY: 'Hatchery',
			NURSERY: 'Nursery',
			POSTNURSERY: 'Post Nursery',
	        GROW1: 'Grow1',
			GROW2: 'Grow2',
			DEAD: 'Dead',
			MISSING: 'Missing',
			FMBS: 'Female, Male, and Brood-stock',
		},
		FISH_SCHOOL: {
			UPDATE_WITHOUT_RECORDS: 'Your Fish school data not yet visible, update cannot be done',
			NEW_FISH_SCHOOL: 'New Fish School',
			RENAME_FISH_SCHOOL: 'Rename School',
			SOLD_ACTIVE_FISH_SCHOOL: 'Sold/Active School',
			ACTIVE_FISH_SCHOOL: 'Set Cage to InActive state',
			FILTERS: {
				STOCKING_WEIGHT: 'Stocking Weight in gram',
				STOCKING_PER_MONTH: 'Stocking Per Month',
				PARAMETERS: 'Parameters',
				SCHOOL_NAME: 'School name',
				SELECT_FEED_TYPE: 'Select Feed Type',
				SELECT_SCHOOL_NAME: 'Select School name',
				SELECT_FROM_SCHOOL_NAME: 'Select From School name',
				SELECT_SCHOOL_STAUS: 'Select School Status',
				SELECT_TO_SCHOOL_NAME: 'Select To School name',
				SOLD_SCHOOL_NAME: 'Sold School name',
				CHOOSE_SCHOOL_NAME: 'Choose School Name',
				START_TYPING_NAME: 'Start typing name',
				SCHOOL_NAME_NOT_FOUND: 'School name not found',
				SCHOOL_NAME_NOT_SELECTED: 'Not selected',
				SELL_TARGET: 'Sell Target in grams',
				TONS_PER_DAY: 'Tons per day',
				SELECT_PARAMETER_TYPE: 'Parameter Type',
				STATUS: {
					ACTIVE: 'Active',
					INACTIVE: 'Inactive',
					SOLD: 'Sold'
				},
				FOOD_DATE: 'Start date',
				FOOD_END_DATE: 'End date',
				NUM_OF_DAYS: '# of days',
				PANEL: {
					HEADER: 'Fish school filters',
					FEEDS_HEADER: 'Daily Feeds filters',
					HEADER_PARAM: 'Parameters filters',
					DESCRIPTION: 'Choose Filters',
					VIEW_SOLD_HEADER: 'VIEW SOLD',
					REPORTS_HEADER: 'REPORTS',
					SOLD_HEADER: 'Sold Fish school filters',
					SOLD_FROM: 'Move From',
					SALE_DATE: 'Move Date',
					SOLD_TO: 'Move To',
				}
			},
			TABLE: {
				SETTINGS: 'Settings',
				NEW_PARAMETER: 'New Parameter',
				PARAMETER_NAME: 'Parameter Name',
				PARAMETER_VALUE: 'Parameter Value',
				PARAMETER_REMOVE: 'Remove parameter',
				SELECTED_DATE: 'Selected Date',
				AGE: 'Age',
				MANUAL_WEIGHT: 'M Weight',
				AVERAGE_GRAMS: 'Avg. Grams',
				NUMBER_OF_FISH: '# Fish',
				TOTAL_KG: 'Total KG',
				FEED_PLAN: 'Feed Plan',
				REDUCED_FEED_PLAN: 'Reduced Feed Plan',
				GIVEN_FEED: 'Given Feed',
				TOTAL_FOOD: 'Total Feed',
				FOOD_TYPE: 'Feed Type',
				MORTALITY: '# Lost',
				FCR: 'FCR',
				SOLD: 'Sold',
				SOLD_WEIGHT: 'Avg. Sold',
				SPECIE: 'Manual feed',
				ACTIVITY_LOG: 'Activity Log',
				SORT_SIZE: 'Sort Size',
				STAGE: 'Stage',
				NET: 'Net',
				FISH_SOURCE: 'Fish Source',
				FEEDS: 'Feeds',
				LOST_TYPE: 'Lost Type',
				GROW_AVG_WEIGHT: 'Man Avg',
			},
			HISTORY: {
				DATE: 'Date',
				JOURNAL_ID: 'Journal Id',
				TO_JOURNAL_ID: 'To Journal Id',
				FROM_JOURNAL_ID: 'From Journal Id',
				TO_NEW_JOURNAL_ID: 'To New Journal Id',
				FROM_NEW_JOURNAL_ID: 'From New Journal Id',
				FROM_CAGE_NAME: 'From Cage Name',
				TO_CAGE_NAME: 'To Cage Name',
				MOVE_WEIGHT_KG: 'Move Weight Kg',
				AVERAGE_WEIGHT: 'Average Weight',
			},
			VIEW_SOLD: {
				NAME: 'name',
				FEED_DATE: 'Feed Date',
				VIEW_SOLD_FISH: 'View Sold Fish',
				AVERAGE_WEIGHT: 'Average Weight',
				SALE: 'Sale',
				TOTAL_SALE: 'Total Sale',
				TOTAL_WEIGHT: 'Total Weight',
				FCR: 'FCR',
				TOTAL_GIVEN_FOOD: 'Total Given Feed',
			},
			SOLD: {
				FEED_DATE: 'Feed Date',
				SOLD_FISH: 'Sold Fish in Kg',
				SALE_AVG_WEIGHT: 'Sale Avg Weight in grams',
				SALE_TO_MOVED_QUANTITY: 'Total Moved Fish',
				SALE_TO_MOVED_AVG_WEIGHT: 'Moved Avg Weight in grams',
				ADD_NEW_TITLE: 'Add new "sold to" school',
				DELETE_LAST_TITLE: 'Delete last "sold to" school',
			},
			RESULTS: {
				FISH_SCHOOL_UPDATE_SUCCESS: 'Fish schools updated successfully',
				SOLD_FISH_SCHOOL_UPDATE_SUCCESS: 'Sold Fish schools have been updated successfully',
			},
			VALIDATION: {
				SOLD_DUPLICATE_SCHOOL_NAME: 'You have selected same school name: {{name}} in both "Sold From" and "Move To"',
				SOLD_DUPLICATE_SOLD_TO_SCHOOL_NAME: 'You have selected same school name: {{name}} in "Move To"',
			},
			NAME: 'Name',
			CAGE_NAME: 'Cage Name',
			REPORT_NAME: 'Select Report',
			FIELD_NAME: 'SelectField',
			QUANTITY: 'Quantity',
			PREFIX: 'Prefix',
			CREATION_DATE: 'Creation Date',
			PERCENTAGE_TSEMACH: 'Percentage Tsemach',
			FOOD_NAME: 'Feed Name',
			SPECIE: 'Specie',
			AVERAGE_WEIGHT: 'Average Weight',
		},
		REPORTS: {
			FILTERS_TITLE: 'Reports',
			KEY: 'Key',
			VALUES: 'Values',
			OPERATOR: 'Operator',
		},
		AUTHORIZATIONS: {
			NAME: 'Name',
			ACTION: 'Action',
			COMPANY: 'Company',
			USER: 'User',
			FISH_SCHOOL: 'FishSchool',
			FOOD: 'Feed',
			FOOD_INVOICE: 'FeedInvoice',
			FOOD_INVOICE_SLOT: 'FeedInvoiceSlot',
			ACTION_VIEW: 'View',
			ACTION_SAVE: 'Save',
			ACTION_UPDATE: 'Update',
			ACTION_DELETE: 'Delete',
		},
		FOOD: {
			FOOD_TYPE: 'Feed Type',
			CHOOSE_FOOD_TYPE: 'Choose Feed Type',
			TABLE: {
				TITAL: 'Daily Farm Update',
				NAME: 'Name',
				QUANTITY: 'Quantity',
				PREFIX: 'Prefix',
				DAILY_FEED: 'Daily Feed',
				FOOD_TYPE: 'Feed Type',
				LAST_FEED: 'Last Feed',
				FEED_PLAN: 'Feed Plan',
				MORTALITY: 'Mortality',
			},
			FILTERS: {
				CHOOSE_FOOD_NAME: 'Choose feed type',
				START_TYPING_NAME: 'Start typing feed type',
				FOOD_NAME_NOT_FOUND: 'Feed type not found',
			},
			DELETE: {
				CONFIRM_HEADER: 'Confirm Feed Deletion',
				ARE_U_SURE: 'Are you sure you want to delete a feed?',
				CONFIRM_INFO: 'By choosing \'OK\' you will no longer be able to view it or restore it'
			},
			RESULTS: {
				FOOD_UPDATE_SUCCESS: 'Feed updated successfully',
				FOOD_DELETE_SUCCESS: 'Feed deleted successfully'
			},
			VALIDATION: {
				NEW_RECORD_INCOMPLETE: 'New Feed is incomplete please add missing information: {{missingParts}} '
			},
		},
		DELIVERY_NOTES: {
			UPDATE_WITHOUT_RECORDS: 'Your Delivery note data not yet visible, update cannot be done',
			FILTERS: {
				STATUS: {
					SALE: 'Sale',
					SHIPMENT: 'Shipment'
				},
				FOOD_DATE: 'Feed date',
				PANEL: {
					HEADER: 'Delivery Notes Filters',
					DESCRIPTION: 'Choose Delivery Notes'
				},
			},
			TABLE: {
				NAME: 'Feed Name',
				STATUS: 'Status',
				QUANTITY: 'Quantity',
				PREFIX: 'Prefix',
				ACTION_TYPE: 'Action Type',
				RECEIPT: 'Receipt #',
				FOOD_DATE: 'Feed Date',
			},
			DELETE: {
				CONFIRM_HEADER: 'Confirm Delivery Notes Deletion',
				ARE_U_SURE: 'Are you sure you want to delete a delivery notes?',
				CONFIRM_INFO: 'By choosing \'OK\' you will no longer be able to view it or restore it'
			},
			RESULTS: {
				DELIVERY_NOTES_UPDATE_SUCCESS: 'Delivery Notes updated successfully',
				DELIVERY_NOTES_DELETE_SUCCESS: 'Delivery Note deleted successfully',
			},
			VALIDATION: {
				MANDATORY_DATA_MISSING: 'Please fill all mandatory information: Receipt, Feed Date & at least 1 Feed Type'
			}
		},
		TOGGLE_STATUS: {
			TOGGLE_ACTIVE: 'Toggle Active',
			TOGGLE_SOLD: 'Toggle Sold',
		},
		ACTIONS: {
			SAVE: 'Save',
			UPDATE: 'Update',
			ADD: 'Add new',
			CLOSE: 'Close',
			SEARCH: 'Search',
			OK: 'OK',
			CANCEL: 'Cancel',
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION: 'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created'
				}
			}
		}
	}
};
